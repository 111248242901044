<template>
  <div class="askAnswer">
    <van-tabs v-model="active" @change="onTabChange">
      <van-tab title="我的提问">
        <div v-if="askList.length > 0">
          <div class="ask-nums">共{{askList.length}}个问题</div>
          <div v-for="item in askList" :key="item.questId" class="ask-item">
            <div class="ask-goods" @click="onClickGoods(item)">
              <img class="ask-goods-cover" :src="item.titleImg" />
              <div class="ask-goods-info">
                <div class="ask-goods-info-name">{{item.title}}</div>
              </div>
            </div>
            <div class="faq-my-list">
              <div class="ask-answer">
                <div class="head">问题内容：</div>
                <div class="desc">{{item.questionTitle}}</div>
                <div class="info">
                  <div>{{item.createTime}}</div>
                  <div v-if="item.helperList.length == 0">暂无回答</div>
                  <div v-else>已有{{item.helperList.length}}个回答</div>
                </div>
                <div v-if="item.helperList.length > 0">
                  <div class="head">回答列表：</div>
                  <div v-for="answer in item.helperList" :key="answer.helper">
                    <div class="faq-answer">{{answer.message}}</div>
                    <div class="info">
                      <div>{{answer.isSeller == 1 ? '卖家' : answer.helperNick}}</div>
                      <div>{{answer.createTime}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <van-empty v-else description="暂无提问记录" />
      </van-tab>
      <van-tab title="邀请我的">
        <div v-if="inviteList.length > 0">
          <div class="ask-nums">共{{inviteList.length}}个问题</div>
          <div v-for="item in inviteList" :key="item.questId" class="ask-item">
            <div class="ask-goods" @click="onClickGoods(item)">
              <img class="ask-goods-cover" :src="item.titleImg" />
              <div class="ask-goods-info">
                <div class="ask-goods-info-name">{{item.title}}</div>
              </div>
            </div>
            <div class="faq-my-list">
              <div class="ask-answer">
                <div class="head">提问内容：</div>
                <div class="desc">{{item.questionTitle}}</div>
                <div class="info">
                  <div>{{item.createTime}}</div>
                </div>
                <div class="answer-btn">
                  <van-button size="small" type="info" @click="onAnswer(item)">去回答</van-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <van-empty v-else description="暂无邀请记录" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Fetch from '../../common/fetch';
import { GetAskList, GetAnswerList } from '../../common/interface';
import Vue from 'vue';
import { Tab, Tabs, Toast } from 'vant';

Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Toast);

export default {
  name: 'AskAnswer',
  data() {
    return {
      active: 0,
      askList: [],
      inviteList: [],
    };
  },
  mounted() {
    this.fetchAskList();
  },
  methods: {
    onTabChange(index) {
      if (index == 0) {
        this.fetchAskList();
      } else {
        this.fetchInviteList();
      }
    },

    fetchAskList() {
      const params = {
        pageNum: 1,
        pageSize: 100,
      };

      Fetch.post(GetAskList, params).then((res) => {
        this.askList = res.list || [];
      }).catch((err) => {
        Toast.fail(err.msg);
      });
    },

    fetchInviteList() {
      const params = {
        pageNum: 1,
        pageSize: 100,
      };

      Fetch.post(GetAnswerList, params).then((res) => {
        this.inviteList = res.list || [];
      }).catch((err) => {
        Toast.fail(err.msg);
      });
    },

    onClickGoods(item) {
      this.$router.push('/product?id=' + item.productId);
    },

    onAnswer(item) {
      this.$router.push('/faqs/answer?id=' + item.questId);
    }
  },
}
</script>

<style lang="scss" scoped>
.askAnswer {
  background-color: #e8e8ed;
}
.ask-nums {
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
  color: #666;
  font-size: 12px;
  background-color: #e8e8ed;
}

.ask-item {
  position: relative;
  margin-bottom: 10px;
}
.ask-item .ask-goods {
  position: relative;
  padding: 10px;
  height: 65px;
  background-color: #fff;
}
.ask-goods-cover {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 65px;
  height: 65px;
  overflow: hidden;
}
.ask-goods-info {
  margin-left: 75px;
}
.ask-goods-info-name {
  color: #333;
  font-size: 13px;
  height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.faq-my-list {
  padding: 0 10px 10px;
  background-color: #fff;
}
.ask-answer {
  position: relative;
  padding: 0 10px 10px;
  background-color: #f2f2f7;
  border-radius: 2px;
}
.ask-answer .head {
  position: relative;
  height: 30px;
  line-height: 30px;
  color: #666;
  font-size: 12px;
}
.ask-answer .desc {
  position: relative;
  margin-bottom: 5px;
  padding-left: 20px;
  color: #333;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ask-answer .desc::before {
  content: "问";
  display: inline-block;
  margin: -2px 5px 0 -20px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  background-color: #ff9600;
  text-align: center;
  border-radius: 2px;
  vertical-align: middle;
}
.ask-answer .info {
  color: #999;
  font-size: 10px;
  display: flex;
  justify-content: space-between;
}

.faq-answer {
  position: relative;
  margin-bottom: 5px;
  padding-left: 20px;
  color: #333;
  font-size: 16px;
}
.faq-answer::before {
  content: "答";
  display: inline-block;
  margin: -2px 5px 0 -20px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  color: #fff;
  font-size: 13px;
  background-color: #18c461;
  text-align: center;
  border-radius: 2px;
  vertical-align: middle;
}

.answer-btn {
  text-align: right;
}
</style>